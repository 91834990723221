<template>
  <div class="popup_wrap" style="width:1000px; min-height:200px; max-height:600px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <div v-if="rfResultList.length === 0 && oogResultList.length === 0 && dgResultList.length === 0">
        <h1 class="page_title">{{ $t('btn.CMBTK045') }}</h1>
        <div class="content_box">
          <p class="p_nodata">{{ $t('msg.CMBA320.011') }}</p>
        </div>
      </div>
      <div v-if="rfResultList.length > 0">
        <h1 class="page_title">{{ $t('msg.ONEX050P081.001') }}</h1> <!-- B/L RF(RH) Entry -->
        <div class="content_box">
          <table class="tbl_col">
            <colgroup>
              <col style="width:10%">
              <col>
              <col style="width:10%">
              <col style="width:10%">
              <col style="width:10%">
              <col style="width:10%">
              <col style="width:10%">
              <col style="width:10%">
            </colgroup>
            <thead>
              <tr>
                <th scope="col">{{ $t('msg.CSBK100.287') }}</th> <!-- SEQ -->
                <th scope="col">{{ $t('msg.ONEX010P090.049') }}</th> <!-- Container No. -->
                <th scope="col">{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
                <th scope="col">{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
                <th scope="col">{{ $t('msg.ONEX010P090.041') }}</th> <!-- CGO -->
                <th scope="col">{{ $t('msg.ONEX010P090.056') }}</th> <!-- Plug -->
                <th scope="col">{{ $t('msg.ONEX010P090.057') }}</th> <!-- Temp -->
                <th scope="col">{{ $t('msg.ONEX010P090.058') }}</th> <!-- C/F -->
              </tr>
            </thead>
            <tbody v-if="rfResultList.length > 0">
              <tr v-for="(vo, idx) in rfResultList" :key="'cntrRf_' + idx">
                <td>{{ idx + 1 }}</td>
                <td>{{ vo.cntrNo }}</td>
                <td>{{ vo.cntrSzCd }}</td>
                <td>{{ vo.cntrTypCd }}</td>
                <td>{{ vo.cgoTypCd }}</td>
                <td>
                  <input
                    v-model="vo.rfSlecUseYn"
                    :id="'rfSlecUseYn_' + idx"
                    type="checkbox"
                    true-value="Y"
                    false-value="N"
                    disabled
                  >
                  <label :for="'rfSlecUseYn_' + idx"></label>
                </td>
                <td>{{ vo.rfSetupTmpr }}</td>
                <td>{{ vo.rfTmprUnitCd }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="dgResultList.length > 0">
        <h1 class="page_title">{{ $t('msg.ONEX050P081.002') }}</h1> <!-- B/L D/G Entry -->
        <div class="content_box">
          <table class="tbl_col">
            <colgroup>
              <col style="width:10%">
              <col>
              <col style="width:15%">
              <col style="width:15%">
              <col style="width:15%">
              <col style="width:15%">
            </colgroup>
            <thead>
              <tr>
                <th scope="col">{{ $t('msg.CSBK100.287') }}</th> <!-- SEQ -->
                <th scope="col">{{ $t('msg.ONEX010P090.049') }}</th> <!-- Container No. -->
                <th scope="col">{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
                <th scope="col">{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
                <th scope="col">{{ $t('msg.CSBK100.349') }}</th> <!-- UN No. -->
                <th scope="col">{{ $t('msg.CSBK100.378') }}</th> <!-- IMO Class -->
              </tr>
            </thead>
            <tbody v-if="dgResultList.length > 0">
              <tr v-for="(vo, idx) in dgResultList" :key="'cntrDg_' + idx">
                <td>{{ idx + 1 }}</td>
                <td>{{ vo.cntrNo }}</td>
                <td>{{ vo.cntrSzCd }}</td>
                <td>{{ vo.cntrTypCd }}</td>
                <td class="td_border">{{ vo.imdunNo }}</td>
                <td>{{ vo.imoClasCd }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="oogResultList.length > 0">
        <h1 class="page_title">{{ $t('msg.ONEX050P081.003') }}</h1> <!-- B/L OOG Entry -->
        <div class="content_box">
          <table class="tbl_col">
            <colgroup>
              <col>
              <col style="width:9%">
              <col style="width:9%">
              <col style="width:10%">
              <col style="width:9%">
              <col style="width:9%">
              <col style="width:9%">
              <col style="width:9%">
              <col style="width:9%">
              <col style="width:9%">
            </colgroup>
            <thead>
              <tr>
                <th scope="col" rowspan="2">{{ $t('msg.ONEX010P090.049') }}</th> <!-- Container No. -->
                <th scope="col" rowspan="2">{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
                <th scope="col" rowspan="2">{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
                <th scope="col" rowspan="2">{{ $t('msg.ONEX050P081.004') }}</th> <!-- Cargo Type -->
                <th scope="col" rowspan="2">{{ $t('msg.ONEX050P081.005') }}</th> <!-- F/E -->
                <th scope="col" colspan="5">{{ $t('msg.ONEX050P081.006') }}</th> <!-- Over(㎝) -->
              </tr>
              <tr>
                <th class="border_left">OH</th>
                <th>OF</th>
                <th>OB</th>
                <th>OWP</th>
                <th>OWS</th>
              </tr>
            </thead>
            <tbody v-if="oogResultList.length > 0">
              <tr v-for="(vo, idx) in oogResultList" :key="'cntrOog_' + idx">
                <td>{{ vo.cntrNo }}</td>
                <td>{{ vo.cntrSzCd }}</td>
                <td>{{ vo.cntrTypCd }}</td>
                <td>{{ vo.cgoTypCd }}</td>
                <td>{{ vo.feCatCd }}</td>
                <td>{{ vo.ovrHght }}</td>
                <td>{{ vo.ovrFwdLen }}</td>
                <td>{{ vo.ovrBwrdLen }}</td>
                <td>{{ vo.ovrPortLen }}</td>
                <td>{{ vo.ovrStbdLen }}</td>
              </tr>
            </tbody>
          </table>
          <p class="txt_desc">{{ $t('msg.ONEX050P081.007') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ImportBLCntrCgoPop',
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          data: [],
          flag: ''
        }
      }
    }
  },
  data () {
    return {
      rfResultList: [],
      dgResultList: [],
      oogResultList: [],
      flag: ''
    }
  },
  created () {
    // this.init()
    console.log('>>>>>> ImportBLCntrCgoPop <<<<<<')
    console.log(this.parentInfo)
    if (this.parentInfo.flag === 'HZ') {
      this.dgResultList = this.parentInfo.data
    } else if (this.parentInfo.flag === 'OT' || this.parentInfo.flag === 'FR') {
      this.oogResultList = this.parentInfo.data
    } else {
      this.rfResultList = this.parentInfo.data
    }
  },
  methods: {
  }
}
</script>
<style>
  .td_border {border-left: 1px solid #e1e3eb !important;}
  .p_nodata {text-align: center; font-size: 16px; font-weight: 600;}
</style>
