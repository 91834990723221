var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: {
        width: "1000px",
        "min-height": "200px",
        "max-height": "600px",
      },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _vm.rfResultList.length === 0 &&
        _vm.oogResultList.length === 0 &&
        _vm.dgResultList.length === 0
          ? _c("div", [
              _c("h1", { staticClass: "page_title" }, [
                _vm._v(_vm._s(_vm.$t("btn.CMBTK045"))),
              ]),
              _c("div", { staticClass: "content_box" }, [
                _c("p", { staticClass: "p_nodata" }, [
                  _vm._v(_vm._s(_vm.$t("msg.CMBA320.011"))),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.rfResultList.length > 0
          ? _c("div", [
              _c("h1", { staticClass: "page_title" }, [
                _vm._v(_vm._s(_vm.$t("msg.ONEX050P081.001"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "content_box" }, [
                _c("table", { staticClass: "tbl_col" }, [
                  _vm._m(0),
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBK100.287"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.049"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBK100.272"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBK100.273"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.041"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.056"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.057"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.058"))),
                      ]),
                    ]),
                  ]),
                  _vm.rfResultList.length > 0
                    ? _c(
                        "tbody",
                        _vm._l(_vm.rfResultList, function (vo, idx) {
                          return _c("tr", { key: "cntrRf_" + idx }, [
                            _c("td", [_vm._v(_vm._s(idx + 1))]),
                            _c("td", [_vm._v(_vm._s(vo.cntrNo))]),
                            _c("td", [_vm._v(_vm._s(vo.cntrSzCd))]),
                            _c("td", [_vm._v(_vm._s(vo.cntrTypCd))]),
                            _c("td", [_vm._v(_vm._s(vo.cgoTypCd))]),
                            _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: vo.rfSlecUseYn,
                                    expression: "vo.rfSlecUseYn",
                                  },
                                ],
                                attrs: {
                                  id: "rfSlecUseYn_" + idx,
                                  type: "checkbox",
                                  "true-value": "Y",
                                  "false-value": "N",
                                  disabled: "",
                                },
                                domProps: {
                                  checked: Array.isArray(vo.rfSlecUseYn)
                                    ? _vm._i(vo.rfSlecUseYn, null) > -1
                                    : _vm._q(vo.rfSlecUseYn, "Y"),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = vo.rfSlecUseYn,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? "Y" : "N"
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            vo,
                                            "rfSlecUseYn",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            vo,
                                            "rfSlecUseYn",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(vo, "rfSlecUseYn", $$c)
                                    }
                                  },
                                },
                              }),
                              _c("label", {
                                attrs: { for: "rfSlecUseYn_" + idx },
                              }),
                            ]),
                            _c("td", [_vm._v(_vm._s(vo.rfSetupTmpr))]),
                            _c("td", [_vm._v(_vm._s(vo.rfTmprUnitCd))]),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.dgResultList.length > 0
          ? _c("div", [
              _c("h1", { staticClass: "page_title" }, [
                _vm._v(_vm._s(_vm.$t("msg.ONEX050P081.002"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "content_box" }, [
                _c("table", { staticClass: "tbl_col" }, [
                  _vm._m(1),
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBK100.287"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.049"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBK100.272"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBK100.273"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBK100.349"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBK100.378"))),
                      ]),
                    ]),
                  ]),
                  _vm.dgResultList.length > 0
                    ? _c(
                        "tbody",
                        _vm._l(_vm.dgResultList, function (vo, idx) {
                          return _c("tr", { key: "cntrDg_" + idx }, [
                            _c("td", [_vm._v(_vm._s(idx + 1))]),
                            _c("td", [_vm._v(_vm._s(vo.cntrNo))]),
                            _c("td", [_vm._v(_vm._s(vo.cntrSzCd))]),
                            _c("td", [_vm._v(_vm._s(vo.cntrTypCd))]),
                            _c("td", { staticClass: "td_border" }, [
                              _vm._v(_vm._s(vo.imdunNo)),
                            ]),
                            _c("td", [_vm._v(_vm._s(vo.imoClasCd))]),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.oogResultList.length > 0
          ? _c("div", [
              _c("h1", { staticClass: "page_title" }, [
                _vm._v(_vm._s(_vm.$t("msg.ONEX050P081.003"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "content_box" }, [
                _c("table", { staticClass: "tbl_col" }, [
                  _vm._m(2),
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "col", rowspan: "2" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.049"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col", rowspan: "2" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBK100.272"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col", rowspan: "2" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBK100.273"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col", rowspan: "2" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX050P081.004"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col", rowspan: "2" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX050P081.005"))),
                      ]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col", colspan: "5" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX050P081.006"))),
                      ]),
                    ]),
                    _vm._m(3),
                  ]),
                  _vm.oogResultList.length > 0
                    ? _c(
                        "tbody",
                        _vm._l(_vm.oogResultList, function (vo, idx) {
                          return _c("tr", { key: "cntrOog_" + idx }, [
                            _c("td", [_vm._v(_vm._s(vo.cntrNo))]),
                            _c("td", [_vm._v(_vm._s(vo.cntrSzCd))]),
                            _c("td", [_vm._v(_vm._s(vo.cntrTypCd))]),
                            _c("td", [_vm._v(_vm._s(vo.cgoTypCd))]),
                            _c("td", [_vm._v(_vm._s(vo.feCatCd))]),
                            _c("td", [_vm._v(_vm._s(vo.ovrHght))]),
                            _c("td", [_vm._v(_vm._s(vo.ovrFwdLen))]),
                            _c("td", [_vm._v(_vm._s(vo.ovrBwrdLen))]),
                            _c("td", [_vm._v(_vm._s(vo.ovrPortLen))]),
                            _c("td", [_vm._v(_vm._s(vo.ovrStbdLen))]),
                          ])
                        }),
                        0
                      )
                    : _vm._e(),
                ]),
                _c("p", { staticClass: "txt_desc" }, [
                  _vm._v(_vm._s(_vm.$t("msg.ONEX050P081.007"))),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col"),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col"),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col"),
      _c("col", { staticStyle: { width: "9%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
      _c("col", { staticStyle: { width: "9%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticClass: "border_left" }, [_vm._v("OH")]),
      _c("th", [_vm._v("OF")]),
      _c("th", [_vm._v("OB")]),
      _c("th", [_vm._v("OWP")]),
      _c("th", [_vm._v("OWS")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }